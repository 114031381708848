function copyToClipboard(string) {
  let textarea;
  let result;

  try {
    textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.setAttribute('contenteditable', true);
    textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand('copy');
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    document.body.removeChild(textarea);
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
    result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
    if (!result) {
      return false;
    }
  }
  return true;
}

export default {
  methods: {
    copy(data) {
     
      navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
        if (result.state === 'granted') {
          var blob = new Blob([data], { type: 'text/plain' });
          // eslint-disable-next-line no-undef
          var item = new ClipboardItem({ 'text/plain': blob });
          navigator.clipboard.write([item]).then(() => {
            this.$notify({ text: 'Copied', type: 'success' });
          });
        } else {
          console.log("clipboard-permissoin not granted: " + result);
        }
      });
    },

    copyMobile(data) {
      copyToClipboard(data)
      this.$notify({ text: 'Copied', type: 'success' });

    }
  }
}
