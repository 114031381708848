<template lang="pug">
  span.d-flex.flex-row
    v-icon(@click="copySpreadsheetData" :class="{'disabled': disabled}" data-test="candidate-copy").hoverable.copy-btn mdi-content-copy
    v-menu(:disabled="disabled")
      template(v-slot:activator="{ on }")
        v-icon(v-on="on" small :class="{'disabled': disabled}").copy-arrow mdi-menu-down
      v-list.user-menu
        v-list-item(@click="copyName").user-menu__item
          v-list-item-title Copy name
        v-list-item(@click="copyPhone")
          v-list-item-title Copy telephone number
</template>

<script>
import copyMixin from '@/mixins/copy.mixin'

export default {
  name: 'CandidatesCopy',

  mixins: [copyMixin],

  props: {
    multiple: {
      type: Boolean
    },
    activeItems: Array,
    groupedList: Object,
    list: Array,
    model: Object,
    disabled: Boolean,
    custom: Function
  },

  methods: {
    copySpreadsheetData() {
      if (this.disabled) return
      if (this.custom) return this.custom()
      if (this.multiple) this.copyMultiple(this.getMultipleSpreadsheetData())
      else this.copyData(this.model)
    },

    getMultipleSpreadsheetData() {
      let items = this.activeItems.map(item => {
        let index = this.list.findIndex(_item => _item.ID === item.ID)
        return { index, item }
      })
      let activeItemsIndex = this.getIndexesOf(this.activeItems)
      return activeItemsIndex.map(index => this.list[index].getSpreadsheetData(this.$store))
    },

    copyMultiple(data) {
      this.copy(data.join('\n'))
    },

    getIndexesOf(activeItems) {
      if (this.groupedList) {
        let grouped = {}
        for (let group in this.groupedList) {
          grouped[group] = {
            items: []
          }
          // find selected candidates
          let items = this.activeItems.filter(item => this.groupedList[group].items.find(_item => _item.ID === item.ID))
          // find indexes of selected candidates in group and sort because copying has to be in numbered order according to the list
          let indexes = items.map(item => this.groupedList[group].items.findIndex(_item => _item.ID === item.ID)).sort((a, b) => a - b)
          indexes.forEach(index => {
            let indexesInList = this.list.findIndex(item => item.ID === this.groupedList[group].items[index].ID)
            return grouped[group].items.push(indexesInList)
          })
        }
        let itemsByIndex = []
        for (let group in grouped) {
          itemsByIndex = [...itemsByIndex, ...grouped[group].items]
        }
        return itemsByIndex
      }

      return this.activeItems.map(item => this.list.findIndex(_item => _item.ID === item.ID))
          .sort((a, b) => a - b)
    },

    copyData(model) {
      let data = model.getSpreadsheetData(this.$store)
      this.copy(data)
    },

    copyName() {
      if (this.multiple) this.copyMultiple(this.getMultipleDataBy('fullName'))
      else this.copy(this.model.fullName)
    },

    getMultipleDataBy(field) {
      let activeItemsIndex = this.getIndexesOf(this.activeItems)
      return activeItemsIndex.map(index => this.list[index][field])
    },

    copyPhone() {
      if (this.multiple) this.copyMultiple(this.getMultipleDataBy('candidateTel'))
      else this.copy(this.model.candidateTel)
    }
  }
}
</script>

<style lang="scss" scoped>
.copy-arrow::v-deep {
  &:hover {
    color: $primary-color;
  }

  &.disabled {
    opacity: .5;
    color: inherit;
    cursor: not-allowed;
  }

}

.copy-btn::v-deep {
  &.disabled {
    opacity: .5;
    color: inherit !important;
    cursor: not-allowed;
  }
}
</style>
