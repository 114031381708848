import { render, staticRenderFns } from "./CandidatesCopy.vue?vue&type=template&id=6b9c8b36&scoped=true&lang=pug&"
import script from "./CandidatesCopy.vue?vue&type=script&lang=js&"
export * from "./CandidatesCopy.vue?vue&type=script&lang=js&"
import style0 from "./CandidatesCopy.vue?vue&type=style&index=0&id=6b9c8b36&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b9c8b36",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VIcon,VList,VListItem,VListItemTitle,VMenu})
